







































































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-tree {
  width: 100%;
}

.lessonNum {
  display: flex;
  padding: 1rem;
  span {
    padding: 0 1rem;
  }
}

.lessNumcount {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  span {
    width: 10rem;
  }
}

.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-tree-node {
  display: flex;
  align-items: center;
  .el-form-item {
    margin-bottom: 0px;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 25rem;
  font-size: 14px;
}

.ellipsiss {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 23.85rem;
  font-size: 14px;
}

.el-table .warning-row {
  background: red;
}

.el-table .success-row {
  background: #ffffff;
}

.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}

.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}
